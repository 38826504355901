<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Iron Man (also known as Tony Stark), an electrical engineer by training, wears a suit of
        powered armour made of a titanium-gold alloy.
      </p>
      <p class="mb-2">
        Identify the lattice type for titanium metal and the two alloys shown. Deduce the empirical
        formulas of the alloys.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td></td>
            <td style="text-align: center; font-weight: bold">A</td>
            <td style="text-align: center; font-weight: bold">B</td>
            <td style="text-align: center; font-weight: bold">C</td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td style="text-align: left">Structure</td>
            <td><v-img style="max-width: 250px" src="/img/assignments/UofASandboxImg1.png" /></td>
            <td><v-img style="max-width: 250px" src="/img/assignments/UofASandboxImg2.png" /></td>
            <td><v-img style="max-width: 250px" src="/img/assignments/UofASandboxImg3.png" /></td>
          </tr>

          <tr>
            <td style="text-align: left">Formula</td>
            <td><chemical-latex content="Ti" /></td>
            <td>
              <v-select
                v-model="inputs.input1"
                style="max-width: 250px"
                :items="items1Shuffled"
                item-text="text"
                item-value="value"
                label="A"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input2"
                style="max-width: 250px"
                :items="items1Shuffled"
                item-text="text"
                item-value="value"
                label="B"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td style="text-align: left">Lattice</td>
            <td>
              <v-select
                v-model="inputs.input3"
                style="max-width: 250px"
                :items="items2Shuffled"
                item-text="text"
                item-value="value"
                label="C"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input4"
                style="max-width: 250px"
                :items="items2Shuffled"
                item-text="text"
                item-value="value"
                label="D"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input5"
                style="max-width: 250px"
                :items="items2Shuffled"
                item-text="text"
                item-value="value"
                label="E"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>

          <tr>
            <td style="text-align: left">
              Can be described by a closest-packing arrangement of atoms
            </td>
            <td>
              <v-select
                v-model="inputs.input6"
                style="max-width: 250px"
                :items="items3"
                item-text="text"
                item-value="value"
                label="F"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input7"
                style="max-width: 250px"
                :items="items3"
                item-text="text"
                item-value="value"
                label="G"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
            <td>
              <v-select
                v-model="inputs.input8"
                style="max-width: 250px"
                :items="items3"
                item-text="text"
                item-value="value"
                label="G"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';
import StembleLatex from '../StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUofA103SandboxExamQ7A',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
      },
      items1: [
        {text: '$\\ce{TiAu}$', value: 'TiAu'},
        {text: '$\\ce{TiAu2}$', value: 'TiAu2'},
        {text: '$\\ce{TiAu3}$', value: 'TiAu3'},
        {text: '$\\ce{TiAu4}$', value: 'TiAu4'},
        {text: '$\\ce{Ti3Au}$', value: 'Ti3Au'},
        {text: '$\\ce{Ti3Au2}$', value: 'Ti3Au2'},
        {text: '$\\ce{Ti3Au4}$', value: 'TiAu'},
      ],
      items2: [
        {text: 'Primitive cubic', value: 'primitive'},
        {text: 'Body-centered cubic', value: 'bodyCentered'},
        {text: 'Face-centered cubic', value: 'faceCentered'},
      ],
      items3: [
        {text: 'True', value: 'true'},
        {text: 'False', value: 'false'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    items1Shuffled() {
      return seededShuffle(this.items1, this.seed);
    },
    items2Shuffled() {
      return seededShuffle(this.items2, this.seed);
    },
  },
};
</script>
<style scoped></style>
